import React, { useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { UserFactory, } from '@haaretz/htz-user-utils';
import Mutation from '../ApolloBoundary/Mutation';

export const UPDATE_USER = gql`
  mutation updateUser($user: User!) {
    updateUser(user: $user) @client
  }
`;

export const GET_USER = gql`
  query GetUser {
    user: htzUser @client {
      type
      email
      firstName
      lastName
      emailStatus
      anonymousId
      university
    }
  }
`;

const propTypes = {
  /** A mutate function for updating the currently set user */
  updateUser: PropTypes.func.isRequired,
  onUserReady: PropTypes.func,
};

const defaultProps = {
  onUserReady: null,
};

function UserInjector({ updateUser, onUserReady, }) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      // Build a user from a newly parsed (hence :'true' param) cookie state
      const user = new UserFactory(true).build();
      if (onUserReady) onUserReady(user);
      updateUser({ variables: { user, }, });
      isInitialMount.current = false;
    }
  });

  return null;
}
UserInjector.propTypes = propTypes;
UserInjector.defaultProps = defaultProps;

function Wrapper(props) {
  return (
    <>
      <Mutation mutation={UPDATE_USER}>
        {updateUser => <UserInjector updateUser={updateUser} {...props} />}
      </Mutation>
    </>
  );
}

export default Wrapper;
