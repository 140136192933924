// @flow
/* global window document localStorage */

import { useCallback, } from 'react';
import { CookieUtils, } from '@haaretz/htz-user-utils';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useArticleId from '../../hooks/Page/useArticleId';
import { useUser, } from '../User/UserDispenser';
import { getSiteProducts, } from './utils';

const targetingProviders = new Map();

export default function useGlobalAdManagerTargetings() {
  const { user, } = useUser();
  const isWebView = useWebViewChecker();
  const articleId = useArticleId();

  const puAdsTagetingFunc = useCallback(pubAdsServise => {
    if (!pubAdsServise) {
      return;
    }

    const userDetailsCookie = CookieUtils.getCookie('user_details');
    const userProductsCookie = userDetailsCookie?.products ? userDetailsCookie : CookieUtils.getCookie('userProducts');

    // Old mobile-apps uses the 'userProducts' cookie that has a different structure.
    const getUserTypeForSite = userDetailsCookie?.products
      ? getUserTypeForSiteForWeb
      : getUserTypeForSiteForApp;
    const markerAppUser = CookieUtils.getCookie('user'); // This cookie is set by TheMarker app, when a user is 'advisor' or 'agent'
    const pathSegments = getPathSegments();

    targetingProviders.set('react', (() => [ 'true', ]));

    targetingProviders.set('react_platform', (() => (isWebView ? [ 'App', ] : null))); // if useWebViewChecker then [ 'App' ]
    targetingProviders.set('UserType', (() => [ (user.type === 'paying' ? 'payer' : user.type), ])); // user.type (anonymous | registered | payer)
    targetingProviders.set('htz_user_type', (() => getUserTypeForSite('htz', user, userProductsCookie?.products)));
    targetingProviders.set('tm_user_type', (() => getUserTypeForSite('tm', user, userProductsCookie?.products)));
    targetingProviders.set('hdc_user_type', (() => getUserTypeForSite('hdc', user, userProductsCookie?.products)));
    targetingProviders.set('section', (() => (pathSegments && pathSegments.length > 0 ? [ pathSegments[0], ] : null)));
    targetingProviders.set('sub_section', (() => (pathSegments && pathSegments.length > 1 ? [ pathSegments[1], ] : null)));
    targetingProviders.set('articleId', (() => (articleId ? [ articleId, ] : null)));
    targetingProviders.set('bq_campaign_id', (() => localStorage.getItem('GstatCampaign')));
    targetingProviders.set('referrer', (() => (document.referrer.includes('facebook') ? [ 'facebook', ] : null)));
    targetingProviders.set('user', (() => markerAppUser || null));

    targetingProviders.forEach((valueGenerator, key) => {
      const value = valueGenerator && valueGenerator();
      if (value) {
        pubAdsServise.setTargeting(key, value);
      }
    });
  }, [ articleId, isWebView, user, ]);

  return puAdsTagetingFunc;
}

// For Web :: Calculate the user type for a site by its product
function getUserTypeForSiteForWeb(site, user, products = []) {
  if (user.type === 'anonymous') {
    return 'anonymous';
  }

  let userType;

  const siteProducts = getSiteProducts(site);
  const userSubscribedProduct = products.find(prod => siteProducts.includes(prod.prodNum) && prod.status === 'SUBSCRIBED');

  if (userSubscribedProduct) {
    userType = userSubscribedProduct.isTrial ? 'trial' : 'payer';
  }
  else {
    userType = 'registered';
  }

  return userType;
}

// For Mobile App :: Calculate the user type for a site by its product
function getUserTypeForSiteForApp(site, user, products = []) {
  if (user.type === 'anonymous') {
    return 'anonymous';
  }

  let userType;

  const siteProducts = getSiteProducts(site);
  const userSubscribedProduct = products.find(prod => siteProducts.includes(prod.prodNum));

  if (userSubscribedProduct) {
    userType = userSubscribedProduct.trial ? 'trial' : 'payer';
  }
  else {
    userType = 'registered';
  }

  return userType;
}

// Breaks the window URL to path-segments (sections)
function getPathSegments() {
  const path = window.location.pathname.split('/', 3);
  return path && path.filter(part => /^[^.]+$/.test(part));
}
