const personalAreaStyle = Object.freeze({
  mobileMargin: Object.freeze({
    sectionMargin: '2rem',
    inBlockMargin: '3rem',
  }),
  inputStyle: ({ theme, }) => ({
  }),
  mainHeaderAccount: Object.freeze({
    fontWeight: '500',
    extend: ({ theme, }) => ([
      theme.type(7, { untilBp: 'm', }),
      theme.type(6, { fromBp: 'm', }),
    ]),
    textTransform: 'normal',
  }),
  creditCard: Object.freeze({
    linkColor: 'primary',
  }),
  navigationBar: Object.freeze({
    linkColor: 'primary',
  }),
  password: Object.freeze({
    buttonColor: 'primary',
  }),
  resetPasswordText: Object({
    size: ({ theme, }) => theme.type(-1, { from: 'm', }),
  }),
  resetPasswordModal: Object.freeze({
    size: ({ theme, }) => theme.type(-1, { from: 'm', }),
    buttonVariant: 'primary',
  }),
  subscription: Object.freeze({
    manageButtonVariant: 'primaryOpaque',
    cancellationButtonColor: 'primary',
    reasonStage: Object.freeze({
      titleColor: 'primary',
      buttonVariant: 'primaryOpaque',
      linkColor: 'primary',
    }),
    confirmationStage: Object.freeze({
      //titleColor: 'primary',
      rightButtonVariant: 'primaryOpaque',
      leftButtonVariant: 'primary',
    }),
    forceCancelLinkColor: 'primary',
    standbyAngle: '0deg',
  }),
  subscriptionApple: Object.freeze({
    titleColor: 'primary',
    linkColor: 'primary',
  }),
  subscriptionAndroid: Object.freeze({
    titleColor: 'primary',
    linkColor: 'primary',
  }),
  support: Object.freeze({
    linkColor: 'primary',
  }),
  personalAreaBlock: Object.freeze({
    buttonVariant: 'primaryOpaque',
    buttonSize: {
      paddingTop: '0.75rem',
    },
  }),
  getGridTemplateAreas: count => {
    let valueM = '';
    let valueXl = '';

    for (let i = 0; i < Math.floor(count / 4); i += 3) {
      valueM += `"radio${i} radio${i + 1} radio${i + 2}"`;
    }

    for (let i = 0; i < Math.floor(count / 3); i += 4) {
      valueXl += `"radio${i} radio${i + 1} radio${i + 2} radio${i + 3}"`;
    }

    return {
      m: valueM,
      xl: valueXl,
    };
  },
  monthCalButton: Object.freeze({
    firstBorderRadius: '0rem 2rem 2rem 0rem',
    secondBorderRadius: '2rem 0rem 0rem 2rem',
  }),
});

export default personalAreaStyle;
