import App from 'next/app';
import React from 'react';
import { ApolloProvider, } from 'react-apollo';
import log from 'loglevel';
import Head from 'next/head';
import { LevelProvider, } from './components/AutoLevels/LevelContext';
import isQueryValueTrue from './utils/isQueryValueTrue';
import parseBoolean from './utils/parseBoolean';
import GptInitScript from './components/AdManager/GptInitScript';

export default function createApp(AdditionalComponent, WrapperComponent) {
  return class NextApp extends App {
    static async getInitialProps({ Component, ctx, }) {
      const { req, res, } = ctx;
      const initialProps = {};
      let pageProps = {};

      if (res) {
        initialProps.responseWriteHead = res.writeHead.bind(res);
        initialProps.responseEnd = res.end.bind(res);
      }

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      const { asPath, pathname, query, } = ctx;
      return {
        isWebView: isQueryValueTrue(req?.isWebView),
        isDarkMode: isQueryValueTrue(req?.query.darkMode),
        pageProps,
        ...initialProps,
        query: parseBoolean(query),
        asPath,
        pathname,
      };
    }

    componentDidCatch(error, errorInfo) {
      // This is needed to render errors correctly in development / production
      super.componentDidCatch(error, errorInfo);

      console.log(error);
      // TODO: recover from errors or redirect to error page
    }

    render() {
      const {
        pageProps,
        Component,
        initialProps,
        client,
        responseWriteHead,
        responseEnd,
        query,
        asPath,
        pathname,
      } = this.props;

      const { loglevel, } = query;
      log.setLevel(loglevel || process.env.LOGLEVEL || log.levels.ERROR);

      const url = { query, asPath, pathname, };
      return (
        <>
          <Head>
            {/*
            * The viewport tag should be handled by next/head in pages/_app.js.
            * https://nextjs.org/docs/messages/no-document-viewport-meta
            */}
            <meta
              name="viewport"
              key="viewport"
              content={`width=device-width, initial-scale=1.0, minimum-scale=1.0${this.props.isWebView ? ', user-scalable=no, maximum-scale=1.0' : ''}`}
            />
          </Head>
          <GptInitScript />
          <ApolloProvider client={client}>
            <LevelProvider value={1}>
              {WrapperComponent ? (
                <WrapperComponent url={url}>
                  <Component
                    {...pageProps}
                    {...initialProps}
                    url={url}
                    responseWriteHead={responseWriteHead}
                    responseEnd={responseEnd}
                  />
                </WrapperComponent>
              ) : (
                <Component
                  {...pageProps}
                  {...initialProps}
                  url={url}
                  responseWriteHead={responseWriteHead}
                  responseEnd={responseEnd}
                />
              )}
              {AdditionalComponent ? <AdditionalComponent /> : null}
            </LevelProvider>
          </ApolloProvider>
        </>
      );
    }
  };
}
