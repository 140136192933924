export const listStyle = Object.freeze({
  header: {
    verticalType: [
      { until: 's', value: { step: 2, lines: 7, }, },
      { from: 's', until: 'xl', value: 1, },
      { from: 'xl', value: 0, },
    ],
    type: [
      { until: 's', value: { step: 2, lines: 7, }, },
      { from: 's', value: 2, },
    ],
  },
});

export const listViewHeader = Object.freeze({
  title: {
    iconBackTransform: null,
  },
});

export const verticalList = Object.freeze({
  displayBottomText: true,
  teaser: {
    headerTypeScale: isFirst => (
      [
        { until: 's', value: 0, },
        { from: 's', until: 'xl', value: -1, },
        { from: 'xl', value: !isFirst ? -2 : -1, },
      ]),
    kickerTypeScale: isFirst => (
      [
        { until: 's', value: 0, },
        { from: 's', until: 'xl', value: -1, },
        { from: 'xl', value: !isFirst ? -2 : -1, },
      ]),
  },
});

export const benderStyle = Object.freeze({
  mainTitle: Object.freeze({
    text: 'כתבות שאולי פספסתם',
    fontSize: [ { until: 'm', value: 1, }, { from: 'm', value: 2, }, ],
  }),
  title: Object.freeze({
    fontSize: [
      { until: 'm', value: -2, },
      { from: 'm', until: 'l', value: -1, },
      { from: 'l', value: 0, },
    ],
  }),
  image: Object.freeze({
    aspect: 'vertical',
    width: '400',
    quality: 'auto',
  }),
});

export const marcoStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [ { until: 's', value: -1, }, { from: 's', value: 1, }, ],
  }),
});

export const michelleStyle = Object.freeze({
  showOnMobile: false,
  showAuthor: false,
  showTime: false,
});

export const robertoStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [
      { until: 's', value: 4, },
      { from: 's', until: 'l', value: 6, },
      { from: 'l', value: 8.7, },
    ],
    kickerTypeScale: [ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ],
    subtitleTypeScale: [
      { from: 's', until: 'l', value: 0, },
      { until: 'xl', value: 2, },
      { from: 'xl', value: { step: 2, lines: 5, }, },
    ],
    footerTypeScale: [
      { until: 's', value: -3, },
      { from: 's', until: 'xl', value: -2, },
      { from: 'xl', value: -3, },
    ],
    titleVariant: 'primary',
    hdcTitleFontFamily: false,
    showImageMediumBp: true,
  }),
});

export const zappStyle = Object.freeze({
  listViewPadding: [ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem 4rem', }, ],
  dfpItemGridRowStart: 2,
  teaser: Object.freeze({
    gridGap: '1rem',
    headerTypeScale:
      [
        { until: 's', value: 0, },
        { from: 's', until: 'l', value: 1, },
        { from: 'l', value: 0, },
      ],
    footer: {
      showTime: true,
      showRank: true,
      showCommentsCount: true,
    },
  }),
});

export const pazuzuStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [
      { until: 's', value: 0, },
      { from: 's', until: 'l', value: 2, },
      { from: 'l', value: 1, },
    ],
    headerTypeScaleLabel: [
      { until: 's', value: 0, },
      { from: 's', until: 'l', value: 2, },
      { from: 'l', value: 1, },
    ],
    footer: {
      showRank: true,
      showCommentsCount: true,
      showTime: true,
    },
  }),
});

export const leonardoStyle = Object.freeze({
  layoutContainerMiscStyle: {
    paddingTop: '4rem',
    paddingBottom: [ { until: 'l', value: '4rem', }, { from: 'l', value: '6rem', }, ],
  },
  listViewMiscStyle: ({ theme, }) => null,
});

export const boxyStyle = Object.freeze({
  layoutRowBgc: ({ theme, }) => theme.color('neutral', '-2'),
  boxyItem: ({ theme, }) => ({
    themeType: [
      theme.type(3, { untilBp: 'l', lines: 6, }),
      theme.type(4, { fromBp: 'l', untilBp: 'xl', lines: 7, }),
      theme.type(3, { fromBp: 'xl', lines: 6, }),
    ],
  }),
  boxyLabelItem: ({ theme, }) => ({
    themeType: [
      theme.type(3, { untilBp: 'l', lines: 6, }),
      theme.type(5, { fromBp: 'l', untilBp: 'xl', lines: 7, }),
      theme.type(6, { fromBp: 'xl', lines: 8, }),
    ],
  }),
});

export const calculonStyle = Object.freeze({
  calculonTeasers: {
    headerTypeScale: [
      { until: 's', value: 0, },
      { from: 's', until: 'xl', value: 1, },
      { from: 'xl', value: -1, },
    ],
    footer: {
      showTime: false,
      showCommentsCount: true,
      showAuthor: false,
      miscStyles: { padding: '0 1rem 1rem', },
    },
    teaser1: {
      headerTypeScale: [
        { until: 's', value: 1, },
        { from: 's', until: 'xl', value: 2, },
        { from: 'xl', value: 1, },
      ],
      headerWrapperMiscStyles: {
        padding: [
          { until: 'l', value: '0 1rem', },
          { from: 'l', value: '2rem 1rem 0', },
        ],
      },
      footer: {
        showTime: true,
        showCommentsCount: true,
        miscStyles: { padding: '0 1rem 1rem', },
      },
    },
    teaser2: {
      pictureSourceAspect: 'vertical',
      headerWrapperMiscStyles: null,
      footerMiscStyles: null,
    },
    teaser3: {
      rowGap: '1rem',
      teaserHeaderMiscStyles: null,
      footerMiscStyles: null,
    },
    teaser4: {
      rowGap: '1rem',
      headerWrapperMiscStyles: null,
      footerMiscStyles: null,
    },
    teaser5: {
      rowGap: '1rem',
      headerWrapperMiscStyles: {
        padding: '1rem 1rem 0',
      },
    },
  },
});

export const slugsStyle = Object.freeze({
  showExtraLinks: true,
  mainTeaser: {
    rowGap: '1rem',
    headerWrapperMiscStyles: null,
    footer: {
      showCommentsCount: true,
      miscStyles: { textAlign: 'center', },
      showTime: true,
    },
  },
  twoUpTeaser: {
    colGap: [
      { until: 's', value: '1rem', },
      { from: 's', until: 'l', value: '1rem', },
      { from: 'l', value: '2rem', },
    ],
    rowGap: [
      { until: 'l', value: '1rem', },
      { from: 'l', value: '2rem', },
    ],
    headerTypeScale: [ { from: 's', until: 'xl', value: 1, }, ],
    headerWrapperMiscStyles: null,
    footer: {
      showCommentsCount: true,
      showTime: true,
    },
  },
  textualTeaser: {
    rowGap: '2rem',
    headerTypeScale: [ { from: 's', until: 'xl', value: 1, }, ],
    headerWrapperMiscStyles: null,
    footer: {
      showCommentsCount: true,
      showTime: true,
    },
  },
});


export const panucciStyle = Object.freeze({
  colGap: '1rem',
  headerTypeScale: hasImage => (
    [
      { until: 's', value: 0, },
      { from: 's', until: 'xl', value: hasImage ? 0 : 1, },
      { from: 'xl', value: hasImage ? -1 : 0, },
    ]),
  footer: {
    showCommentsCount: true,
    showTime: true,
  },
  horizontalTeaser: {
    headerTypeScale: [
      { until: 'xl', value: 0, },
      { from: 'xl', value: -1, }, ],
  },
});

export const barbadosStyle = Object.freeze({
  displayListViewHeader: false,
  listView: {
    areaTemplate: [
      {
        until: 's', value: `
        "t1"
        "t2"
        "t3"
        "t4"
        "t5"
        `,
      },
      {
        from: 's',
        until: 'l',
        value: `
        "t1 t2"
        "t3 t3"
        "t4 t4"
        "t5 t5"
        `,
      },
      {
        from: 'l',
        value: `
        "t1 t2 t3"
        "t1 t2 t4"
        "t1 t2 t5"
        `,
      },
    ],
    rowGap: '4rem',
    padding: [ { until: 's', value: '4rem 2rem', }, { from: 's', value: '0 4rem 4rem', }, ],
  },
  displayTeasersMobileHdc: isMobile => false,
  teaser1: {
    rowGap: '1rem',
    headerTypeScale: [
      { until: 's', value: 0, },
      { from: 's', until: 'l', value: 1, },
      { from: 'l', value: 0, },
    ],
    footer: {
      showCommentsCount: true,
    },
  },
  teaser2: {
    colGap: '1rem',
    rowGap: '1rem',
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'l', value: 0, },
      { from: 'l', value: -1, },
    ],
    headerWrapperMiscStyles: {
      padding: [
        { until: 's', value: '1rem 1rem 0', },
        { from: 'l', until: 'xl', value: '1rem 0 0', },
      ],
    },
    footer: {
      showCommentsCount: true,
    },
  },
});

export const weekendStyle = Object.freeze({
  sectionMiscStyles: null,
  teaser1N2: {
    headerTypeScale:
    [
      { until: 'm', value: 0, },
      { from: 'm', until: 'l', value: 1, },
      { from: 'l', value: 0, },
    ],
    headerWrapperMiscStyles: null,
  },
  teaser3: {
    areasTemplate: [
      {
        until: 's',
        value: `
      "media media media"
      ".    content    ."
      ".       .       ."
      ".    footer     ."
      ".       .       ."
          `,
      },
      {
        from: 's',
        value: `
      ".       .       ."
      ".    content    ."
      ".    footer     ."
      ".       .       ."
      "media media media"
          `,
      },
    ],
    rowTemplate: [ { from: 's', value: 'minmax(1rem, 1fr) auto auto 1fr auto', }, ],
    headerWrapperMiscStyles: null,
    footer: {
      miscStyles: null,
    },
  },
  teaser4: {
    lineBgMiscStyles: ({ theme, }) => (
      theme.type(2, { lines: 5, fromBp: 'xl', })
    ),
    hMiscStyles: null,
  },
  teaser5: {
    headerTypeScale:
    [
      { until: 'm', value: 1, },
      { from: 'm', until: 'l', value: 2, },
      { from: 'l', until: 'xl', value: 1, },
      { from: 'xl', value: 0, },
    ],
    headerWrapperMiscStyles: {
      alignSelf: 'end',
    },
    areasTemplate: [
      {
        until: 's',
        value: `
          "media  media  media"
          ".     content     ."
          ".        .        ."
          ".     footer      ."
          ".        .        ."
          `,
      },
      {
        from: 's',
        value: `
          ".   .  .   .     ."
          ".   .  .   .     ."
          ". media . content ."
          ". media . footer  ."
          ".   .  .   .     ."
          ".   .  .   .     ."`,
      },
    ],
  },
});

export const mousepadStyle = Object.freeze({
  teaser: {
    footer: {
      showCommentsCount: true,
    },
  },
});

export const hawkingStyle = Object.freeze({
  hawkingMainTeaser: {
    colGap: '1rem',
    headerTypeScale: [
      { until: 's', value: 1, },
      { from: 's', until: 'l', value: 2, },
      { from: 'l', until: 'xl', value: 0, },
      { from: 'xl', value: 1, },
    ],
    headerWrapperMiscStyles: null,
    footer: {
      showTime: true,
      showRank: true,
      showCommentsCount: true,
      miscStyles: null,
    },
  },
  teaser: {
    headerWrapperMiscStyles: {},
    headerTypeScale: [ { until: 'xl', value: 0, }, { from: 'xl', value: -1, }, ],
    footer: {
      showAuthor: false,
      showCommentsCount: true,
      miscStyles: null,
    },
  },
});

export const santaStyle = Object.freeze({
  teaser: {
    headerTypeScale:
      [
        { until: 's', value: -1, },
        { from: 's', until: 'l', value: 2, },
        { from: 'xl', value: 0, },
      ],
    footer: {
      showTime: true,
      showCommentsCount: true,
      miscStyles: null,
    },
  },
});

export const tateStyle = Object.freeze({
  teaser: {
    colGap: '2rem',
    rowGap: '0.5rem',
    paddingTop: isFirst => (isFirst
      ? [
        { until: 's', value: 0, },
        { from: 's', until: 'xl', value: '1rem', },
        { from: 'xl', value: '2rem', },
      ]
      : [ { until: 'xl', value: '1rem', }, { from: 'xl', value: '2rem', }, ]),
    paddingBottom: isFirst => (isFirst
      ? [ { until: 's', value: 0, }, { from: 's', value: '1rem', }, ]
      : '1rem'),
    footer: {
      showTime: true,
      showCommentsCount: true,
    },
  },
});


export const conradAndWongStyle = Object.freeze({
  headerTypeScale: isConrad => (
    [
      { until: 's', value: 2, },
      { from: 's', until: 'l', value: 6, },
      { from: 'l', until: 'xl', value: 5, },
      { from: 'xl', value: isConrad ? 5 : 4, },
    ]
  ),
});

export const donbotStyle = Object.freeze({
  teasers: {
    mainTeaser: {
      rowGap: [ { until: 'xl', value: '1rem', }, { from: 'xl', value: '2rem', }, ],
      contentMiscStyles: {
        padding: [ { until: 'xl', value: '0 2rem', }, { from: 'xl', value: '0 4rem', }, ],
      },
      footer: {
        showTime: true,
        showCommentsCount: true,
      },
    },
    teaser: {
      headerTypeScale: [ { until: 's', value: 0, }, { from: 'xl', value: -1, }, ],
      footer: {
        showAuthor: false,
        showCommentsCount: true,
      },
    },
  },
});

export const spotStyle = Object.freeze({
  gradient: 'var(--spot-gradient)',
});
