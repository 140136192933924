const variationA = Object.freeze({
  titleType: [
    { until: 's', value: 7, },
    { from: 's', until: 'l', value: 8, },
    { from: 'l', value: 10, },
  ],
  subTitleType: [
    { until: 's', value: 2, },
    { from: 's', until: 'xl', value: 3, },
    { from: 'xl', value: 2, },
  ],
  shareBarMarginTop: [],
  magazineHeaderMarginTop: [],
});

const variationB = Object.freeze({
  headerTextMaxWidth: '85rem',
  titleType: [
    { until: 's', value: 7, },
    { from: 's', until: 'l', value: 8, },
    { from: 'l', until: 'xl', value: { step: 8, lines: 10, }, },
    { from: 'xl', value: { step: 10, lines: 12, }, },
  ],
  subTitleType: [
    { until: 's', value: 2, },
    { from: 's', until: 'l', value: 3, },
    { from: 'l', until: 'xl', value: 1, },
    { from: 'xl', value: 2, },
  ],
  shareBarMarginTop: '5rem',
});

const faceArticleStyle = Object.freeze({
  variationA,
  variationB,
  headerMetaPaddingTop: [ { until: 'l', value: '5rem', }, ],
  headerMetaPaddingBottom: [ { until: 'l', value: '2rem', }, ],
});

export default faceArticleStyle;
