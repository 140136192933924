/* global window document */
import React from 'react';
import App from 'next/app';
import { ApolloClient, } from 'apollo-client';
import { ApolloProvider, } from 'react-apollo';
import { InMemoryCache, IntrospectionFragmentMatcher, } from 'apollo-cache-inmemory';
import { createHttpLink, } from 'apollo-link-http';
import { onError, } from 'apollo-link-error';
import { ApolloLink, } from 'apollo-link';
import { createPersistedQueryLink, } from 'apollo-link-persisted-queries';
import gql from 'graphql-tag';
import log from 'loglevel';
import config from 'config';
import fetch from 'isomorphic-unfetch';
import { Agent, } from 'https';
import { UserFactory, } from '@haaretz/htz-user-utils';
import Router from 'next/router';
import timeoutSignal from 'timeout-signal';
import switchToDomain from '../utils/switchToDomain';

// Basic structure for user data object (Apollo store)
const defaultUser = {
  type: null,
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  emailStatus: null,
  token: null,
  anonymousId: null,
  university: false,
  __typename: 'HtzUser',
};

const isProduction = config.has('configName') ? config.get('configName') === 'production' : false;
// const isQA = config.has('configName') ? config.get('configName') === 'qa' : false;

const connectToDevTools = !isProduction
  ? true
  : typeof window !== 'undefined'
    ? window.location.search.includes('apollo=true')
    : false;

// const clusterGql = config.has('gqlInCluster') ? config.get('gqlInCluster') : false;

function createApolloClient(initialState, appDefaultState, ctx) {
  const { req, } = ctx || {};

  // temp solution for problem with k8s
  const hostname = initialState.ROOT_QUERY !== undefined
    ? initialState.ROOT_QUERY.hostname
    : req.hostname === 'htz-front-app.haaretz.co.il'
      ? 'www.haaretz.co.il'
      : req.hostname === 'themarker-app.themarker.com'
        ? 'www.themarker.com'
        : req.hostname === 'hdc-front-app.haaretz.com'
          ? 'www.haaretz.com'
          : req.hostname;

  const referer = initialState.ROOT_QUERY !== undefined ? initialState.ROOT_QUERY.referer : req.headers.referer;
  // 'UA-39927280-1' and 'UA-589309-8' are the test Google Analytics Ids,
  // production apps should pass the production id as ENV variable
  const tmUa = initialState.ROOT_QUERY !== undefined
    ? initialState.ROOT_QUERY.googleAnalyticsId.tmUa
    : process.env.TM_GOOGLE_ANALYTICS_ID || 'UA-39927280-1';
  const htzUa = initialState.ROOT_QUERY !== undefined
    ? initialState.ROOT_QUERY.googleAnalyticsId.htzUa
    : process.env.HTZ_GOOGLE_ANALYTICS_ID || 'UA-589309-8';
  const hdcUa = initialState.ROOT_QUERY !== undefined
    ? initialState.ROOT_QUERY.googleAnalyticsId.hdcUa
    : process.env.HDC_GOOGLE_ANALYTICS_ID || 'UA-589309-2';

  // const graphqlLink = clusterGql && req && !isProduction
  //   ? `http://htz-${ (isQA && typeof process !== 'undefined' && process.env.GRAPHQL_SUB_DOMAIN) || 'graphql'}`
  //   : switchToDomain(hostname, config.get('service.graphql.link'));

  const dateFormat = (req !== undefined ? req.dateFormat : Router.query.dateFormat) || 'MM-yyyy';
  const bot = req !== undefined ? req.bot : Router.query.bot;
  const isPreview = (req !== undefined ? req.isPreview : Router.query.isPreview) || false;
  const platform = (req !== undefined ? req.platform : Router.query.platform) || 'mobile';

  const isServerSide = typeof document === 'undefined';

  let url = config.get(isServerSide ? 'service.graphql.linkCluster' : 'service.graphql.link');

  if (isPreview) {
    url = isServerSide ? 'http://htz-graphql' : url.replace('www.', 'htz-graphql.');
  }

  const graphqlLink = switchToDomain(hostname, url);

  function lengthGreatOrEqualThan(param = '', length = 1000) {
    return (param?.length || 0) >= length;
  }

  function checkOperationIsList(operation) {
    return (operation?.operationName || '').endsWith('ListQuery');
  }

  function checkOperationIsRainbow(operation) {
    return (operation?.operationName || '') === 'GetMarketingData';
  }

  function getHash(string) {
    // set variable hash as 0
    let hash = 0;

    // if the length of the string is 0, return 0
    if (string.length === 0) return hash;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < string.length; i++) {
      const ch = string.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + ch;
      // eslint-disable-next-line operator-assignment, no-bitwise
      hash = hash & hash; // Convert to 32bit integer
    }

    return Math.abs(hash);
  }

  const httpLink = (operation, forward) => {
    const isList = checkOperationIsList(operation);
    const isRainbow = checkOperationIsRainbow(operation);
    const isLongExcludeParam = isRainbow || (isList && lengthGreatOrEqualThan(operation?.variables?.exclude));

    const hashUrlParams = typeof document !== 'undefined' && isLongExcludeParam ? `?operationName=${operation.operationName}&hash=${getHash(JSON.stringify(operation?.variables || {}))}` : '';

    let uri = `${graphqlLink}${hashUrlParams}`;

    if (!uri.includes('operationName')) {
      uri = `${uri}${uri.includes('?') ? '&' : '?'}operation=${operation.operationName}`;
    }

    const apolloLink = createHttpLink({
      uri, // Server URL (must be absolute)
      credentials: 'include', // Additional fetch() options like `credentials` or `headers`
      ...(config.has('localCert') && config.get('localCert') && typeof window === 'undefined' ? {
        fetchOptions: {
          agent: new Agent({
            rejectUnauthorized: false,
          }),
        },
      } : typeof window === 'undefined' ? { signal: timeoutSignal(10000), } : {}),
      headers: {
        cookie: req !== undefined ? req.header('cookie') : undefined,
        hostname,
        // headers gets stringified so we make sure preview is not sent at all if not defined
        ...(req !== undefined
          ? req.query.preview
            ? { preview: req.query.preview, previewUserId: req.query.userId, }
            : {}
          : {}),
        dateFormat,
        isPreview,
        platform,
        bot,
        ...(operation.operationName ? { operationName: operation.operationName, } : {}),
      },
      fetch,
      includeExtensions: true,
    });

    return apolloLink.request(operation, forward);
  };

  const errorLink = onError(({ response, operation, graphQLErrors, networkError, }) => {
    if (Array.isArray(graphQLErrors)) {
      const isClient = typeof window !== 'undefined';
      const style = isClient ? identity : styleStdout;

      log.error(
        `${style('[GraphQL error]:', [ 'red', 'bold', ])} Operation Name: ${style(operation.operationName, [ 'magenta', 'underline', ])
        }`
      );
      graphQLErrors.forEach(({ message, locations, path, }) => log.error(
        `${style('[GraphQL error]:', [ 'red', 'bold', ])} Message: ${message}, Location: ${(locations || []).map(
          ({ line, column, }) => `${style(`line: ${line}`, [ 'magenta', ])}, ${style(`column: ${column}`, [ 'magenta', ])}`
        ).join('; ')}, Path: ${path}`
      )
      );
    }
    if (networkError) {
      log.error(`${'[Network error]:'} ${typeof networkError === 'string' ? networkError : JSON.stringify(networkError)}`);
    }

    // if (response) response.errors = null;
    if (ctx && ctx.res && typeof ctx.res.status === 'function') {
      if (graphQLErrors && graphQLErrors[0].extensions && graphQLErrors[0].extensions.response && graphQLErrors[0].extensions.response.status) {
        ctx.res.status(graphQLErrors[0].extensions.response.status);
      }
      else {
        ctx.res.status(503);
      }
    }
  });

  const apolloLinks = [
    errorLink,
    (operation, forward) => {
      const isList = checkOperationIsList(operation);
      const isRainbow = checkOperationIsRainbow(operation);
      const isLongExcludeParam = isRainbow || (isList && lengthGreatOrEqualThan(operation?.variables?.exclude));

      const apolloLink = createPersistedQueryLink({
        useGETForHashedQueries: !isPreview && !isLongExcludeParam,
      });

      return apolloLink.request(operation, forward);
    },
    httpLink,
    // terminatingLink
  ];

  if (typeof document !== 'undefined' && (document.location.search).includes('showGraphql=true')) {
    // eslint-disable-next-line no-underscore-dangle
    window.__GQL = window.__GQL || {};

    apolloLinks.unshift((operation, forward) => forward(operation).map(response => {
      const key = `${operation.operationName}:  ${JSON.stringify(operation.variables)}`;
      const context = operation.getContext();
      const { response: res, } = context || {};
      const { headers, } = res || {};
      const sourcePath = (headers || new Map()).get('Full-Source-Path');

      // eslint-disable-next-line no-underscore-dangle
      window.__GQL[key] = {
        ...operation,
        ...response,
        sourcePath: sourcePath && decodeURIComponent(sourcePath),
      };

      return response;
    }));
  }

  if (typeof window === 'undefined') {
    apolloLinks.unshift((operation, forward) => forward(operation).map(response => {
      if (response?.data?.Page === null && !response?.errors) {
        if (ctx && ctx.res && typeof ctx.res.status === 'function') {
          ctx.res.status(404);
        }
      }
      else if (response?.errors) {
        if (response?.data?.Page === null && !response?.errors) {
          if (ctx && ctx.res && typeof ctx.res.status === 'function') {
            ctx.res.status(503);
          }
        }
      }

      return response;
    }));
  }

  const link = ApolloLink.from(apolloLinks);

  const customFragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  });

  const inMemoryCache = new InMemoryCache({
    fragmentMatcher: customFragmentMatcher,
    dataIdFromObject: ({
      // Shared
      __typename,
      contentId,
      inputTemplate,

      // Teaser
      teaserId,
      image,
      media,
      mobileImage,

      // List
      isExpanded,
      style,
      items,
      view,
      title,
    }) => {
      let cacheKey = null;

      const contentIdCacheKey = teaserId || contentId;

      if (contentIdCacheKey) {
        cacheKey = `${inputTemplate || __typename}:${contentIdCacheKey}`;
      }

      const teaserImageCacheKey = mobileImage?.contentId || image?.contentId || media?.contentId;

      const isList = inputTemplate === 'htz_list_List';

      if (isList) {
        cacheKey = `${cacheKey}_${style || view}_${isExpanded}`;
      }

      if (cacheKey && teaserImageCacheKey) {
        cacheKey += `_${teaserImageCacheKey}`;
      }

      const isRelatedArticle = inputTemplate === 'RelatedArticle';
      if (cacheKey && isRelatedArticle && typeof title === 'string') {
        cacheKey += `_${title.replace(/ /g, '-')}`;
      }

      return cacheKey;
    },
  }).restore(initialState || {});

  const userFromReq = req
    ? Object.assign(
      {},
      defaultUser,
      new UserFactory(true, req.header('Cookie') || '', hostname).build()
    )
    : undefined;
  // try to rehydrate user on client from server state (client)
  const userFromCache = inMemoryCache.data.data['$ROOT_QUERY.user'];

  const userData = userFromReq && userFromReq.anonymousId ? userFromReq : userFromCache;
  const user = Object.assign({}, defaultUser, userData);

  inMemoryCache.writeData({
    data: {
      pageType: null,
      ariaLive: {
        assertiveMessage: '',
        politeMessage: '',
        __typename: 'AriaLive',
      },
      hostname,
      scroll: {
        velocity: null,
        x: 0,
        y: 0,
        __typename: 'Scroll',
      },
      user,
      // ids of representedContent already rendered by a list on the page
      listDuplicationIds: [],
      personalListPq: [],
      googleAnalyticsId: {
        tmUa,
        htzUa,
        hdcUa,
        __typename: 'GoogleAnalyticsId',
      },
      ...(appDefaultState ? appDefaultState(referer) : {}),
      isReadyToRenderExternals: initialState?.ROOT_QUERY?.isReadyToRenderExternals !== false,
    },
  });

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    connectToDevTools,
    link,
    cache: inMemoryCache,
    resolvers: {
      Mutation: {
        updateListDuplication: (_, variables, { cache, }) => {
          const query = gql`
            query {
              listDuplicationIds @client
            }
          `;
          const response = cache.readQuery({ query, });
          const ids = [ ...response.listDuplicationIds, ];
          variables.ids.forEach(id => {
            // check if item already exists in list and if not push it in
            if (ids.indexOf(id) === -1) ids.push(id);
          });
          const data = {
            listDuplicationIds: ids,
          };
          cache.writeData({ data, });
          // resolver needs to return something / null https://github.com/apollographql/apollo-link-state/issues/160
          return { ids, };
        },
        toggleZen: (_, variables, { cache, }) => {
          const query = gql`
            query {
              zenMode @client
            }
          `;
          const response = cache.readQuery({ query, });
          const data = { zenMode: !response.zenMode, };
          cache.writeData({ data, });
          // resolver needs to return something / null https://github.com/apollographql/apollo-link-state/issues/160
          return null;
        },
        updateArticleSection: (_, { id, name, url, }, { cache, }) => {
          const data = {
            articleSection: {
              id,
              name,
              url,
              __typename: 'ArticleSection',
            },
          };
          cache.writeData({ data, });
          // resolver needs to return something / null https://github.com/apollographql/apollo-link-state/issues/160
          return null;
        },
        updateScroll: (_, { x, y, velocity, }, { cache, }) => {
          const data = {
            scroll: {
              velocity,
              x,
              y,
              __typename: 'Scroll',
            },
          };
          cache.writeData({ data, });
          // resolver needs to return something / null https://github.com/apollographql/apollo-link-state/issues/160
          return null;
        },
        updateUser: (_, { user, }, { cache, }) => {
          const data = {
            htzUser: {
              ...user,
              __typename: 'UserHtz',
            },
          };
          cache.writeData({ data, });
          // resolver needs to return something / null https://github.com/apollographql/apollo-link-state/issues/160
          return null;
        },
        updateHostname: (_, { hostname, }, { cache, }) => {
          const data = {
            hostname,
          };
          cache.writeData({ data, });
          return null;
        },
        updatePersonalListPq: (_, { id, offset, }, { cache, }) => {
          const query = gql`
            query {
              personalListPq @client {
                pqId
                offset
              }
            }
          `;
          const previous = cache.readQuery({ query, });

          let data;
          let originalOffset = 0;

          const pqElement = previous.personalListPq.find(({ pqId, }) => pqId === id);
          if (pqElement) {
            const newOffsetItem = {
              pqId: pqElement.pqId,
              offset: pqElement.offset + offset,
              __typename: 'pqItem',
            };
            originalOffset = pqElement.offset;
            const restOfArr = previous.personalListPq.filter(({ pqId, }) => pqId !== id);
            data = { personalListPq: [ ...restOfArr, newOffsetItem, ], };
          }
          else {
            const newItem = {
              pqId: id,
              offset,
              __typename: 'pqItem',
            };
            data = {
              personalListPq: [ ...previous.personalListPq, newItem, ],
            };
          }

          cache.writeData({ data, });

          return { originalOffset, };
        },
      },
    },
  });
}

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext} ctx
 */
const initApolloClient = (initialState, appDefaultState, ctx) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, appDefaultState, ctx);
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, appDefaultState, ctx);
  }

  return globalApolloClient;
};

/**
 * Installs the Apollo Client on NextPageContext
 * or NextAppContext. Useful if you want to use apolloClient
 * inside getStaticProps, getStaticPaths or getServerSideProps
 * @param {NextPageContext | NextAppContext} ctx
 */
export const initOnContext = (ctx, appDefaultState) => {
  const inAppContext = Boolean(ctx.ctx);

  // We consider installing `withApollo({ ssr: true })` on global App level
  // as antipattern since it disables project wide Automatic Static Optimization.
  if (process.env.NODE_ENV !== 'production') {
    if (inAppContext) {
      console.warn(
        'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n'
        + 'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n'
      );
    }
  }

  // Initialize ApolloClient if not already done
  const apolloClient = ctx.apolloClient
    || initApolloClient(ctx.apolloState || {}, appDefaultState || {}, inAppContext ? ctx.ctx : ctx);

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null;

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient;
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient;
  }

  return ctx;
};

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 * @param  {Object} withApolloOptions
 * @param  {Boolean} [withApolloOptions.ssr=false]
 * @returns {(PageComponent: ReactNode) => ReactNode}
 */
export const withApollo = ({ ssr = false, } = {}) => (PageComponent, appDefaultState) => {
  // eslint-disable-next-line react/prop-types
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    let client;
    if (apolloClient) {
      // Happens on: getDataFromTree & next.js ssr
      client = apolloClient;
    }
    else {
      // Happens on: next.js csr
      client = initApolloClient(apolloState, appDefaultState, undefined);
    }
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} client={client} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';
    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async ctx => {
      const inAppContext = Boolean(ctx.ctx);
      const { apolloClient, } = initOnContext(ctx, appDefaultState);

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }
      else if (inAppContext) {
        pageProps = await App.getInitialProps(ctx);
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        const { AppTree, ctx: { req, }, } = ctx;
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if dataFromTree is enabled
        if (ssr && AppTree) {
          try {
            // Import `@apollo/react-ssr` dynamically.
            // We don't want to have this in our client bundle.
            const { getDataFromTree, } = await import('@apollo/react-ssr');

            // Since AppComponents and PageComponents have different context types
            // we need to modify their props a little.
            let props;
            if (inAppContext) {
              props = { ...pageProps, apolloClient, req, };
            }
            else {
              props = { pageProps: { ...pageProps, apolloClient, }, };
            }

            // Take the Next.js AppTree, determine which queries are needed to render,
            // and fetch them. This method can be pretty slow since it renders
            // your entire AppTree once for every query. Check out apollo fragments
            // if you want to reduce the number of rerenders.
            // https://www.apollographql.com/docs/react/data/fragments/
            await getDataFromTree(<AppTree {...props} />);
          }
          catch (error) {
            console.error('### Apollo error', error?.networkError?.result?.errors[0].message);
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error

            console.error('Error while running `getDataFromTree`', error);
            log.error('Error while running `getDataFromTree`', error);
          }

          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          // Head.rewind();
        }
      }

      return {
        ...pageProps,
        // Extract query data from the Apollo store
        apolloState: apolloClient.cache.extract(),
        // Provide the client for ssr. As soon as this payload
        // gets JSON.stringified it will remove itself.
        apolloClient: ctx.apolloClient,
      };
    };
  }

  return WithApollo;
};

// Very basic style util, as 'chalk' breaks the code in old browsers due to object spread syntax.
const styleCodes = {
  red: 31,
  magenta: 35,

  bold: 1,
  underline: 4,
  clear: 0,
};

const getStyle = style => (style in styleCodes ? `\x1b[${styleCodes[style]}m` : '');

function styleStdout(str, styles) {
  return `${styles.map(getStyle).join('')}${str}${getStyle('clear')}`;
}

function identity(x) {
  return x;
}
