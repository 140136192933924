const footerStyle = Object.freeze({
  fontStack: 'base',
  borderWidth: '1px',
  lines: 2,
  borderStyle: 'solid',
  footerMobileBorderStyle: Object.freeze({
    borderWidth: '1px',
    lines: 2,
    borderStyle: 'solid',
  }),
  footerDesktopPaddingTop: '13rem',
});

export default footerStyle;
