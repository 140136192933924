const paywallStyle = Object.freeze({
  illustration: 'astronaut',
  fontFamily: 'base',
  type: 1,
  topBannerLayout: {
    margin: '5rem 0',
    gridTemplateAreas: '"image content"',
    gridTemplateColumns: [ { until: 's', value: '19rem 1fr', }, { from: 's', value: '32rem 1fr', }, ],
  },
});
export default paywallStyle;
