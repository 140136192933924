const timelineArticleStyle = Object.freeze({
  titleType: [
    { until: 's', value: 7, },
    { from: 's', until: 'l', value: 8, },
    { from: 'l', value: 10, },
  ],
  subTitleType: [
    { until: 's', value: 2, },
    { from: 's', until: 'xl', value: 3, },
    { from: 'xl', value: 2, },
  ],
  itemDateType: 2,
  titleColor: ({ theme, }) => null,
});

export default timelineArticleStyle;
