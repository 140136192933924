import React, { useContext, } from 'react';

const Level = React.createContext(1);

export const LevelProvider = Level.Provider;

export const LevelConsumer = Level.Consumer;

export function useHeaderLevel() {
  return useContext(Level);
}
