import config from 'config';
import fontStacksWebView from './fontStacksWebView';

const assetPrefix = config.has('assetPrefix') ? config.get('assetPrefix') : '';

const fallbackFont = '"Helvetica Neue",tahoma,Helvetica,Arial,sans-serif';

const fontStacks = Object.freeze({
  webfonts: [
    [
      'Open Sans Hebrew',
      [
        `${assetPrefix}/static/fonts/OpenSansHebrewLight.woff2`,
        `${assetPrefix}/static/fonts/OpenSansHebrewLight.woff`,
      ],
      { fontWeight: 200, fontDisplay: 'fallback', localAlias: [ 'OpenSansHebrew-Light', ], },
    ],
    [
      'Open Sans Hebrew',
      [
        `${assetPrefix}/static/fonts/OpenSansHebrewRegular.woff2`,
        `${assetPrefix}/static/fonts/OpenSansHebrewRegular.woff`,
      ],
      { fontWeight: 400, fontDisplay: 'fallback', localAlias: [ 'OpenSansHebrew-Regular', ], },
    ],
    [
      'Open Sans Hebrew',
      [
        `${assetPrefix}/static/fonts/OpenSansHebrewBold.woff2`,
        `${assetPrefix}/static/fonts/OpenSansHebrewBold.woff`,
      ],
      { fontWeight: 700, fontDisplay: 'fallback', localAlias: [ 'OpenSansHebrew-Bold', ], },
    ],
  ],
  base: `"Open Sans Hebrew",${fallbackFont}`,
  alt: fallbackFont,
  commercial: fallbackFont,
  infographics: `"Open Sans Hebrew",${fallbackFont}`,
  ...fontStacksWebView,
});

export default fontStacks;
