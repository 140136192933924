// consts
import abnerStyle from './consts/abnerStyle';
import alertsStyle from './consts/alertsStyle';
import appPromotionToolStyle from './consts/appPromotionToolStyle';
import articleStyle from './consts/articleStyle';
import bps from './consts/bps';
import breadCrumbsStyle from './consts/breadCrumbsStyle';
import breakingNewsStyle from './consts/breakingNewsStyle';
import breakingNewsNotificationsStyle from './consts/breakingNewsNotificationsStyle';
import btnStyle from './consts/btnStyle';
import captionStyles from './consts/captionStyles';
import commentsStyle from './consts/commentsStyle';
import debtPopup from './consts/debtPopup';
import faceArticleStyle from './consts/faceArticleStyle';
import fontStacks from './consts/fontStacks';
import footerStyle from './consts/footerStyle';
import gridStyle from './consts/gridStyle';
import inputStyle from './consts/inputStyle';
import layoutStyle from './consts/layoutStyle';
import magazineArticleStyle from './consts/magazineArticleStyle';
import mastheadStyle from './consts/mastheadStyle';
import marketingBannerStyle from './consts/marketingBannerStyle';
import mobileStyle from './consts/mobileStyle';
import myAlertsStyle from './consts/myAlertsStyle';
import navMenuStyle from './consts/navMenuStyle';
import personalAreaStyle from './consts/personalAreaStyle';
import timelineArticleStyle from './consts/timelineArticleStyle';
import tldrStyle from './consts/tldrStyle';
import ratingStyle from './consts/ratingStyle';
import reviewArticleStyle from './consts/reviewArticleStyle';
import conferenceArticleStyle from './consts/conferenceArticleStyle';

import {
  listStyle,
  listViewHeader,

  barbadosStyle,
  benderStyle,
  boxyStyle,
  calculonStyle,
  conradAndWongStyle,
  donbotStyle,
  spotStyle,
  hawkingStyle,
  leonardoStyle,
  marcoStyle,
  michelleStyle,
  mousepadStyle,
  robertoStyle,
  panucciStyle,
  pazuzuStyle,
  santaStyle,
  slugsStyle,
  tateStyle,
  verticalList,
  weekendStyle,
  zappStyle,
} from './consts/listStyle';
import audioPlayerStyle from './consts/audioPlayerStyle';
import cardStyle from './consts/cardStyle';
import newsletterStyle from './consts/newsletterStyle';
import contactUsStyle from './consts/contactUsStyle';
import podcastTeasersStyle from './consts/podcastTeasersStyle';
import searchEngineStyle from './consts/searchEngineStyle';
import selectStyle from './consts/selectStyle';
import serviceByMailStyle from './consts/serviceByMailStyle';
import specialPromotionStyle from './consts/specialPromotionStyle';
import teaserStyle from './consts/teaserStyle';
import tabElementStyle from './consts/tabElementStyle';
import topNewsStyle from './consts/topNewsStyle';
import liveBlogArticleStyle from './consts/liveBlogArticleStyle';
import recipeArticleStyle from './consts/recipeArticleStyle';
import osakaStyle from './consts/osakaStyle';
import pageDateTime from './consts/pageDateTime';
import paywallStyle from './consts/paywallStyle';
import paywallMidpageStyle from './consts/paywallMidpage';
import quoteStyle from './consts/quoteStyle';
import readingListStyle from './consts/readingListStyle';
import resetPasswordModalStyle from './consts/resetPasswordModalStyle';
import typeConf from './consts/typeConf';
import shareBarPopup from './consts/shareBarPopup';
import subscribePopupStyle from './consts/subscribePopupStyle';
import ePaperStyle from './consts/ePaperStyle';
import coronaHomeStory from './consts/coronaHomeStory';
import {
  teaserI18n,
  appPromotionTooli18n,
  a11yMenuI18n,
  alertsI18n,
  adsI18n,
  articleLayoutI18n,
  authorPage,
  audioPlayerI18n,
  breadcrumbsI18n,
  breakingNewsStrip,
  broadcastListI18,
  cancellationModalI18n,
  clickTrackerI18n,
  coastHeaders,
  commentI18n,
  commentFormI18n,
  commentSentI18n,
  commentsSectionI18n,
  commentsCountI18n,
  commercialListI18n,
  coronaI18n,
  errorPageI18n,
  faceAffairsI18n,
  faceExclusivesI18n,
  footerDesktopI18n,
  footerSocialLinksI18n,
  footerMobileListsI18n,
  fryListI18n,
  galleryI18n,
  giftShareI18n,
  greetingsI18n,
  homepageLogoLink,
  shareBarI18n,
  sliderCaptionI18n,
  headerSearchI18n,
  hotTopicsI18n,
  liveBlogI18n,
  magazineExclusivesI18n,
  magazineRecipesI18n,
  mastheadSubscriptionI18n,
  mobileAdditionalShare,
  mobileNavigationMenuI18n,
  mobileQuickRegistrationI18n,
  mobileReadingList,
  mobileSearchI18n,
  mobileUserMenuI18n,
  myAlertsI18n,
  navigationMenuI18n,
  newsletterI18n,
  singleNewsletterI18n,
  osakaI18n,
  paywallI18n,
  paywallMiniregI18n,
  paywallPhoneForArticleI18n,
  podcastChannelNames,
  podcastChannelTitle,
  podcastChannelTitleMobile,
  podcastI18n,
  promotionsI18n,
  tldrPromotionLink,
  timeDuration,
  timeDurationEmpty,
  ratingI18n,
  survey,
  surveyPie,
  readingListActionsI18n,
  readingListPageI18n,
  BingI18n,
  readingListMenuI18n,
  recipeIngredientsI18n,
  recipeInstructionsI18n,
  recipeTipsI18n,
  recipeRatingI18n,
  reviewRatingI18n,
  recipesSearchI18n,
  seoI18n,
  seriesArticleI18n,
  serviceByMailI18n,
  storyArticleI18n,
  tagsElementI18n,
  tateI18n,
  textInputI18n,
  tldrDefaultContent,
  topNewsI18n,
  userMenuI18n,
  userStoryTopicI18n,
  validationMailStrip,
  welcomePageI18n,
  zenTextI18n,
  zoominText,
  zoomoutText,
  selectAriaLabel,
  marketingTools,
  footballHeaders,
  nbaHeaders,
  groupBarTabs,
  playoffsI18n,
  btnOptions,
  timeLabels,
  zenNonPayingUsers,
  printNonPayingUsers,
  readingListDisabled,
  adBlockPage,
  dayMap,
  pushwooshCustomPopup,
  words,
  wordlePaywall,
  infographicsI18n,
  algoliaI18n,
  commentsPaywallBlocker,
  imageGallery,
  labelsI18n,
  gameArticleI18n,
  personalAreaI18n,
  EPaperI18n,
  downloadApplicationPopupI18n,
  resetPasswordModalI18n,
  authorTooltipI18n,
  twentyQuestionsI18n,
  conferenceArticleI18n,
  fomoToolTipI18n,
} from './consts/i18n';

// methods
import getColor from './methods/getColor';
import getDelay from './methods/getDelay';
import getDuration from './methods/getDuration';
import getTimingFunction from './methods/getTimingFunction';
import getTransition from './methods/getTransition';
import getTransitionString from './methods/getTransitionString';
import getMqString from './methods/getMqString';
import getZIndex from './methods/getZIndex';
import pxToRem from './methods/pxToRem';
import typesetter from './methods/typesetter';
import mq from './methods/mq';

/**
 * Haaretz theme object
 */
const htzTheme = Object.freeze({
  // Constants
  abnerStyle,
  alertsStyle,
  appPromotionToolStyle,
  articleStyle,
  audioPlayerStyle,
  barbadosStyle,
  benderStyle,
  boxyStyle,
  calculonStyle,
  conradAndWongStyle,
  donbotStyle,
  spotStyle,
  michelleStyle,
  bps,
  breadCrumbsStyle,
  breakingNewsStyle,
  breakingNewsNotificationsStyle,
  btnStyle,
  captionStyles,
  commentsStyle,
  debtPopup,
  direction: 'rtl',
  hawkingStyle,
  gridStyle,
  faceArticleStyle,
  fontStacks,
  footerStyle,
  // font for elements that are framed (mainly relevant for haaretz.com)
  framedFont: 'base',
  imageGallery,
  inputStyle,
  listStyle,
  listViewHeader,
  leonardoStyle,
  liveBlogArticleStyle,
  recipeArticleStyle,
  layoutStyle,
  logoType: 'htz',
  magazineArticleStyle,
  mastheadStyle,
  marcoStyle,
  marketingBannerStyle,
  mobileStyle,
  mousepadStyle,
  myAlertsStyle,
  navMenuStyle,
  podcastTeasersStyle,
  cardStyle,
  newsletterStyle,
  contactUsStyle,
  santaStyle,
  searchEngineStyle,
  selectStyle,
  serviceByMailStyle,
  slugsStyle,
  specialPromotionStyle,
  tabElementStyle,
  tateStyle,
  teaserStyle,
  topNewsStyle,
  osakaStyle,
  pageDateTime,
  pazuzuStyle,
  panucciStyle,
  paywallStyle,
  paywallMidpageStyle,
  quoteStyle,
  ratingStyle,
  readingListStyle,
  resetPasswordModalStyle,
  typeConf,
  verticalList,
  shareBarPopup,
  subscribePopupStyle,
  weekendStyle,
  ePaperStyle,
  zappStyle,
  robertoStyle,
  personalAreaStyle,
  timelineArticleStyle,
  tldrStyle,
  reviewArticleStyle,
  conferenceArticleStyle,

  // I18n
  teaserI18n,
  appPromotionTooli18n,
  a11yMenuI18n,
  alertsI18n,
  adsI18n,
  articleLayoutI18n,
  authorPage,
  audioPlayerI18n,
  BingI18n,
  breadcrumbsI18n,
  breakingNewsStrip,
  broadcastListI18,
  cancellationModalI18n,
  clickTrackerI18n,
  coastHeaders,
  commentI18n,
  commentFormI18n,
  commentSentI18n,
  commentsSectionI18n,
  commentsCountI18n,
  commercialListI18n,
  coronaI18n,
  errorPageI18n,
  faceAffairsI18n,
  faceExclusivesI18n,
  footerDesktopI18n,
  footerSocialLinksI18n,
  footerMobileListsI18n,
  fryListI18n,
  homepageLogoLink,
  galleryI18n,
  giftShareI18n,
  greetingsI18n,
  headerSearchI18n,
  hotTopicsI18n,
  liveBlogI18n,
  magazineExclusivesI18n,
  magazineRecipesI18n,
  mastheadSubscriptionI18n,
  mobileAdditionalShare,
  mobileNavigationMenuI18n,
  mobileQuickRegistrationI18n,
  mobileReadingList,
  mobileSearchI18n,
  mobileUserMenuI18n,
  myAlertsI18n,
  navigationMenuI18n,
  newsletterI18n,
  singleNewsletterI18n,
  osakaI18n,
  podcastI18n,
  promotionsI18n,
  tldrPromotionLink,
  timeDuration,
  timeDurationEmpty,
  paywallI18n,
  paywallMiniregI18n,
  paywallPhoneForArticleI18n,
  podcastChannelNames,
  podcastChannelTitle,
  podcastChannelTitleMobile,
  pushwooshCustomPopup,
  ratingI18n,
  readingListActionsI18n,
  readingListPageI18n,
  readingListMenuI18n,
  recipeIngredientsI18n,
  recipeInstructionsI18n,
  recipeTipsI18n,
  reviewRatingI18n,
  recipeRatingI18n,
  recipesSearchI18n,
  seoI18n,
  seriesArticleI18n,
  serviceByMailI18n,
  storyArticleI18n,
  tagsElementI18n,
  tateI18n,
  textInputI18n,
  tldrDefaultContent,
  topNewsI18n,
  userMenuI18n,
  userStoryTopicI18n,
  validationMailStrip,
  welcomePageI18n,
  words,
  wordlePaywall,
  zenTextI18n,
  zoominText,
  zoomoutText,
  selectAriaLabel,
  marketingTools,
  survey,
  surveyPie,
  timeLabels,
  zenNonPayingUsers,
  printNonPayingUsers,
  readingListDisabled,
  adBlockPage,
  dayMap,
  infographicsI18n,
  algoliaI18n,
  commentsPaywallBlocker,
  labelsI18n,
  gameArticleI18n,
  personalAreaI18n,
  EPaperI18n,
  coronaHomeStory,
  downloadApplicationPopupI18n,
  resetPasswordModalI18n,
  authorTooltipI18n,
  conferenceArticleI18n,
  twentyQuestionsI18n,
  fomoToolTipI18n,

  // Methods
  color: getColor,
  getDelay,
  getDuration,
  getTimingFunction,
  getTransition,
  getTransitionString,
  getMqString,
  getZIndex,
  mq,
  pxToRem,
  shareBarI18n,
  sliderCaptionI18n,
  footballHeaders,
  nbaHeaders,
  groupBarTabs,
  playoffsI18n,
  btnOptions,
  type: typesetter,
});

export default htzTheme;
