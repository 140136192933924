const searchEngineStyle = Object.freeze({
  fontStack: 'base',
  logoSize: [ { until: 's', value: 3, }, { from: 's', value: 5, }, ],
  inputFontWeight: 400,
  resultsInputFontWeight: 700,
  placeholderFontWeight: 400,
  hotTopicsHeadlineMobileFontWeight: '700',
});

export default searchEngineStyle;
