/* @flow */
import type { PxToUnitConverter, } from './pxTo.js';
import pxTo from './pxTo.js';

/**
 * A `px` to `rem` converter<br />
 * **Warning:** This can potentially (and most likely will) break the vertical rhythm and
 * is meant for internal library use. When converting `px` values to `rem`, opt
 * for the configuration based function that is generated by `pxToRemFactory()`
 *
 * @param {number|number[]} pxVal
 *   The number, in pixels to convert into `rem`.
 * @param {number} context
 *   The number, in pixels to be used as the base for converting `pxVal` to `rem`.
 *   Should be the font-size of the `html` element.
 *
 * @returns {srting} `pxVal` converted to `rem` based on `context`.
 * @private
 *
 * @example
 * // Single value
 * pxToRem(12, 6) // => '2rem'
 *
 * // Array of values
 * pxToRem([12, 24], 6) // => '2rem 4rem'
 */
const pxToRem: PxToUnitConverter = /* #__PURE__ */ pxTo('rem');

export default pxToRem;
