const tldrStyle = Object.freeze({
  subscribeButtonStyle: {
    marginBottom: "2.5rem",
  },
  tldrPromotion: {
    textAlign: "center",
  },
});

export default tldrStyle;
