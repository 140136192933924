import { createColorGetter, } from '@haaretz/htz-css-tools';

import colors from '../consts/colors';

const getVar = createColorGetter(colors);

function getColor(name, variant = null, opacity = 1) {
  const colorVar = getVar(name, variant);
  const isRgb = colorVar.startsWith('var');

  const colorVal = isRgb ? `rgba(${colorVar},${opacity})` : colorVar;
  return colorVal;
}

export default getColor;
