const abnerStyle = Object.freeze({
  quoteRotate: 0,
  titleFontFamily: 'inherit',
  opHead: {
    teaserHeaderTypeScale: [ { until: 's', value: 0, }, { from: 'xl', value: -1, }, ],
    stackedAboveMobilePadding: '1rem 2rem',
  },
  editorialTeaser: {
    headerTypeScale: [
      { until: 's', value: 0, },
      { from: 's', until: 'xl', value: 3, },
      { from: 'xl', value: 2, },
    ],
    authorsTypeScale: [ { until: 's', value: -2, }, { from: 'xl', value: -1, }, ],
    padding: [ { until: 's', value: '2rem', }, { from: 's', value: '1rem 2rem', }, ],
  },
  abnerPaginator: {
    colTemplate: [ { until: 'l', value: '1fr', }, { from: 'l', value: '4fr 5fr 5fr 5fr 5fr', }, ],
  },
  quoteTeaserHeaderTypeScale: [ { from: 'xl', value: -1, }, ],
  editorialParagraph: ({ theme, }) => ({
    size: [ theme.type(0, { fromBp: 's', untilBp: 'l', }),
      theme.type(-1, {
        fromBp: 'l',
        untilBp: 'xl',
        lines: 3.5,
      }),
      theme.type(-2, { fromBp: 'xl', }),
      theme.mq({ until: 's', }, { display: 'none', }),
      theme.mq({ from: 's', until: 'l', }, { height: '16rem', }),
      theme.mq({ from: 'l', }, { height: '21rem', }),
    ], }),
});

export default abnerStyle;
