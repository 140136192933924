const breakingNewsStyle = Object.freeze({
  buttonVariant: 'primaryOpaque',
  wrapperStyle: ({ theme, }) => ({
    borderBottom: `solid 2px ${theme.color('neutral', '-6')}`,
  }),
  buttonBoxModel: ({ theme, }) => theme.btnStyle.boxModel,
  stripController: {
    iconBackMiscStyles1: { transform: 'rotate(180deg)', },
    iconBackMiscStyles2: null,
  },
});

export default breakingNewsStyle;
