export default Object.freeze({
  textSpacing: '2rem',
  wrapper: {
    backgroundColor: [ 'white', ],
    color: [ 'neutral', '-2', ],
    font: 'base',
    miscStyles: {
      paddingStart: '4rem',
      paddingEnd: '4rem',
      paddingTop: [ { until: 's', value: '14rem', }, { from: 's', value: '24rem', }, ],
      maxWidth: '90rem',
    },
  },
  logo: {
    size: [ { until: 's', value: 3.333, }, { from: 's', value: 7, }, ],
    color: [ 'primary', '-2', ],
    miscStyles: {
      position: 'absolute',
      top: '3rem',
      end: '3rem',
    },
  },
  illustration: {
    color: [ 'primary', '-2', ],
    miscStyles: {
      position: 'absolute',
      top: '3rem',
      start: '3rem',
    },
  },
  header: {
    color: [ 'neutral', ],
    miscStyles: {
      textAlign: [ { from: 's', value: 'center', }, ],
      marginBottom: [ { until: 's', value: '2rem', }, { from: 's', value: '3rem', }, ],
      marginTop: [ { until: 's', value: '2rem', }, { from: 's', value: '0', }, ],
      type: [ { until: 's', value: 2, }, { from: 's', value: 4, }, ],
    },
  },
  link: {
    color: [ 'primary', 'base', ],
  },
  footer: {
    flexDirection: [ { from: 's', value: 'column', }, ],
    alignItems: [ { from: 's', value: 'center', }, ],
  },
  actionButton: {
    type: -1,
  },
  closeButton: {
    color: [ 'neutral', ],
  },
});
