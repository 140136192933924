const ePaperStyle = Object.freeze({
  layoutStyle: ({ theme, }) => ({
    display: [ { until: 's', value: 'flex', }, { from: 's', value: 'block', }, ],
  }),
  layoutContainerStyle: ({ theme, }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '9rem',
    ...theme.mq({ until: 's', }, {
      maxWidth: '90vw',
      paddingTop: '3rem',
      paddingBottom: '7rem',
    }),
    ...theme.mq({ from: 's', }, {
      paddingTop: '5rem',
    }),
  }),
  imageStyle: ({ theme, }) => ({
    extend: [
      theme.mq({ until: 's', }, {
        maxWidth: '32rem',
      }),
      theme.mq({ from: 's', }, {
        maxWidth: '40rem',
      }),
    ],
  }),
  blockStyle: ({ theme, }) => ({
    textAlign: 'center',
    paddingTop: '1rem',
    extend: [
      theme.mq({ until: 's', }, {
        marginBottom: '3.5rem',
      }),
      theme.mq({ from: 's', }, {
        marginBottom: '4rem',
      }),
    ],
  }),
  titleStyle: ({ theme, }) => ({
    extend: [
      theme.type(2, { until: 's', }),
      theme.type(5, { from: 's', }),
    ],
  }),
  textStyle: ({ theme, }) => ({
    extend: [
      theme.type(1, { from: 's', }),
      theme.mq({ until: 's', }, {
        marginTop: '1rem',
      }),
      theme.mq({ from: 's', }, {
        marginTop: '3rem',
      }),
    ],
  }),
  buttonStyle: ({ theme, }) => ({
    ...theme.type(1.75, { from: 's', }),
  }),
  loginLinkStyle: ({ theme, }) => ({
    marginStart: '1rem',
    textDecoration: 'underline',
    textDecorationSkip: 'ink',
    width: 'fit-content',
  }),
});

export default ePaperStyle;
