import { useRouter, } from 'next/router';
import isQueryValueTrue from '../../utils/isQueryValueTrue';

export default function usePreview() {
  const router = useRouter();

  const isPreview = isQueryValueTrue(router.query.isPreview);

  return { previewId: router.query.previewId, isPreview, };
}
