/**
 * Convert string to boolean.
 * @param  {String}  value  - String to convert
 * @return {?}  Returns the results of the conversion.
 */
function parseBoolFromString(value) {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
}

/**
 * Recursively test values for conversion.
 * @param  {?}  value  - String to convert
 * @return {?}  Returns the results of the conversion.
 */
function parseValue(value) {
  let result = value;
  if (typeof value === 'string') {
    result = parseBoolFromString(value);
  }

  return result;
}

/**
 * Recursively convert object strings to boolean.
 * @param  {Object}  obj  - Object to iterate over
 * @return {Object}  Returns new object (shallow copy).
 */
function parseObject(obj) {
  const result = {};
  let key;
  let value;

  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      value = obj[key];
      result[key] = parseValue(value);
    }
  }

  return result;
}

export default parseObject;
