/* global window */
import config from 'config';
import { getSubdomain, } from './util/domain-utils';

const htz = 'haaretz.co.il';
const hdc = 'haaretz.com';
const tm = 'themarker.com';

/**
 * Returns a site configuration, which is a plain object with the following keys:
 * ssoDomain, ssoKey, siteId
 *  ssoDomain is the domain of the SSO backend service
 *  ssoKey is the string used to identify the cookie ('user_details' cookie keys)
 *  siteId is a number (as a string) that is used with the backend service
 * @param {string} hostname the hostname for which to create a configuration for
 * @return {V} site configuration object, as detailed
 */
export default function createSiteConfig(hostname = window.location.hostname) {
  if (!hostname && !(window && window.location && window.location.hostname)) {
    throw new Error('Tried creating a config with no window.location.hostname context!');
  }
  // const defaultHostname = 'haaretz.co.il';
  const configurations = new Map();
  const domain = shouldUseDefaultHostname(hostname)
    ? getDefaultSite(hostname)
    : getSubdomain(hostname);
  // const domain = isIpAddress(hostname) ? defaultHostname : getSubdomain(hostname);
  const newSsoDomain = config.get('service.sso');
  const ssoDomain = config.get('service.sso');
  const purchaseDomain = config.get('service.msPurchaseUrl');

  configurations.set(htz, {
    newSsoDomain,
    ssoDomain,
    ssoKey: 'user_details',
    siteId: '80',
    purchaseDomain,
  });

  configurations.set(tm, {
    newSsoDomain,
    ssoDomain,
    ssoKey: 'user_details',
    siteId: '10',
    purchaseDomain,
  });

  configurations.set(hdc, {
    newSsoDomain,
    ssoDomain,
    ssoKey: 'user_details',
    siteId: '85',
    purchaseDomain,
  });
  const siteConfig = configurations.get(domain);
  if (!siteConfig) {
    throw new Error('No configuration found for hostname:', domain);
  }
  return siteConfig;
}

function getDefaultSite(hostname = 'haaretz.co.il') {
  if (hostname.includes('haaretz') && !hostname.includes('com')) {
    return htz;
  }
  if (hostname.includes('themarker')) {
    return tm;
  }
  if (hostname.includes('haaretz')) {
    return hdc;
  }
  return htz;
}

function shouldUseDefaultHostname(hostname) {
  const isIpAddress = suspectedIp => /(?!(10\.|172\.(1[6-9]|2\d|3[01])\.|192\.168\.).*)(?!255\.255\.255\.255)(25[0-5]|2[0-4]\d|[1]\d\d|[1-9]\d|[1-9])(\.(25[0-5]|2[0-4]\d|[1]\d\d|[1-9]\d|\d)){3}/.test(
    suspectedIp
  );
  const isProxy = /(proxy)|-/g.test(hostname);
  return isIpAddress(hostname) || isProxy;
}
