// NOTE : this style is for pushwoosh custom pop up, the color need to be explicit
const subscribePopupStyle = Object.freeze({
  subscribeBtnBgColor: '#0b7eb5',
  askLaterBtnTextColor: '#0b7eb5',
  iconBgc: '#ffe70c',
  fontWeight: '700',
  textColor: '#000',
  padding: '16px 16px 4px',
  justifyContent: 'center',
});

export default subscribePopupStyle;
