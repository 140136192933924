import { useRouter, } from 'next/router';
import isQueryValueTrue from '../utils/isQueryValueTrue';


export default function useWebViewChecker() {
  const router = useRouter();

  if (!router || !router.query) return false;

  return isQueryValueTrue(router.query.isWebView);
}
