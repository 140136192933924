/* global window googletag */
import Head from 'next/head';
import * as React from 'react';
import useGlobalAdManagerTargetings from './targetingData';

export default function GptInitScript() {
  const globalTargetingFunc = useGlobalAdManagerTargetings();

  React.useEffect(() => {
    window.googletag = window.googletag || { cmd: [], };
    googletag.cmd.push(() => {
      globalTargetingFunc(googletag.pubads());
    });
  }, [ globalTargetingFunc, ]);

  return (
    <>
      <Head>
        <script key="gpt-script" async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
      </Head>
      {/* eslint-disable-next-line react/no-danger */}
      <script dangerouslySetInnerHTML={{ __html: `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.pubads().collapseEmptyDivs();
          googletag.pubads().enableSingleRequest();
          googletag.pubads().setCentering(true);
          googletag.enableServices();
        });`, }}
      />
    </>
  );
}
