const liveBlogArticleStyle = Object.freeze({
  updatesButtonTop: Object.freeze({
    default: '0',
    withOsaka: [
      { until: 'xl', value: '25rem', },
      { from: 'xl', value: '23rem', },
    ],
    withStickyMasthead: '8.5rem',
  }),
  gradient: 'var(--main-gradient)',
});

export default liveBlogArticleStyle;
