/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, } from '@apollo/react-hooks';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function Mutation({ children, mutation, ...options }) {
  const [ mutateFunc, results, ] = useMutation(mutation, options);

  return children(mutateFunc, results);
}

const MutationErrorBoundary = props => (
  <ErrorBoundary>
    <Mutation {...props} />
  </ErrorBoundary>
);

export default MutationErrorBoundary;
