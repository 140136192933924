const quoteStyle = Object.freeze({
  fontWeight: 700,
  visualElementHeight: '1.2rem',
  visualElementMarginBottom: '1.5rem',
  imageMarginBottom: 0,
  iconQuoteSize: 6,
  iconQuoteMarginBottom: 0,
  citeVerticalPadding: '0.5rem',
  type: [
    { until: 's', value: { step: 3, lines: 6, }, },
    { from: 's', until: 'l', value: { step: 4, lines: 7, }, },
    { from: 'l', until: 'xl', value: 1, },
    { from: 'xl', value: 2, },
  ],
  citeType: [
    { until: 'l', value: -1, },
    { from: 'l', until: 'xl', value: 0, },
    { from: 'xl', value: -1, },
  ],
});

export default quoteStyle;
