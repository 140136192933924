/* eslint-disable react/prop-types */
import React from 'react';
import { useApolloClient, } from '@apollo/react-hooks';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function ApolloClientProvider({ children, }) {
  const client = useApolloClient();

  return children(client);
}

const ApolloBoundaryConsumer = props => (
  <ErrorBoundary>
    <ApolloClientProvider {...props} />
  </ErrorBoundary>
);

export default ApolloBoundaryConsumer;
