const article = Object.freeze({
  marginStart: [
    { until: 'l', value: '0', },
    { from: 'l', until: 'xl', value: '27rem', },
    { from: 'xl', value: '30rem', },
  ],
  marginEnd: [
    { until: 'l', value: '0', },
    { from: 'l', until: 'xl', value: '57rem', },
    { from: 'xl', value: '51rem', },
  ],
});

const articleLink = Object.freeze({
  typeScale: [
    { until: 's', value: 0, },
    { from: 's', value: -1, },
  ],
  baseLinkStylesExtend: ({ theme, }) => ([
    theme.type(-1, { untilBp: 'xl', }),
    theme.type(-2, { fromBp: 'xl', }),
  ]),
});

const paragraphLink = Object.freeze({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomWidthActive: '2px',
});

const body = Object.freeze({
  marginBottom: [
    { until: 'xl', value: '4rem', },
    { from: 'xl', value: '3rem', },
  ],
  maxWidth: '90rem',
  width: [
    { from: 's', until: 'l', value: '80rem', },
    { from: 'l', until: 'xl', value: '70.5rem', },
    { from: 'xl', value: '80rem', },
  ],
  margin: [ { from: 's', until: 'l', value: 'auto', }, ],
  marginStart: [
    { until: 's', value: '2rem', },
    { from: 'l', value: '4rem', },
  ],
  marginEnd: [ { until: 's', value: '2rem', }, ],
  displayTldr: true,
});

const paragraphStyles = Object.freeze({});

/**
 * Contains theme for article header elements: kicker, title
 * @type {Object}
 */
const header = Object.freeze({
  kickerFontSize: [
    { until: 'm', value: 3, },
    { from: 'm', until: 'l', value: 4, },
    { from: 'l', until: 'xl', value: 5, },
    { from: 'xl', value: 6, },
  ],
  titleFontSize: [
    { until: 'm', value: 3, },
    { from: 'm', until: 'l', value: 4, },
    { from: 'l', until: 'xl', value: 5, },
    { from: 'xl', value: 6, },
  ],
  headlineElementCaptionMiscStyles: {
    paddingInlineStart: '1rem',
    paddingTop: '0.5rem',
  },
  breadcrumbs: {
    type: [ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ],
    margins: {
      marginTop: [ { until: 's', value: '2rem', }, ],
    },
  },
  subTitleMarginTop: '1rem',
  articleHeaderMetaCreditMarginTop: '0.5rem',
  bylineFontSize: [
    { until: 's', value: -2, },
    { from: 's', until: 'l', value: -1, },
    { from: 'l', until: 'xl', value: 0, },
    { from: 'xl', value: -1, },
  ],
  marginStart: [
    { until: 'l', value: '2rem', },
    { from: 'l', value: '4rem', },
  ],
  marginEnd: [
    { until: 'm', value: '2rem', },
    { from: 'm', until: 'l', value: '2rem', },
  ],
  showFacebookEngagementCount: false,
  metaTopMarginMobile: '5rem',
  metaBottomMarginMobile: '2rem',
  authorFontSize: [ { until: 's', value: -1, }, ],
});

const articleStyle = Object.freeze({
  article,
  articleLink,
  header,
  body,
  paragraphStyles,
  paragraphLink,
});

export default articleStyle;
