// @flow
import React from 'react';
import type { ChildrenArray, Node, } from 'react';
import { createApp, ApolloConsumer, userScheme, } from '@haaretz/htz-components';
import { withApollo, } from '@haaretz/app-utils';
import { StyleProvider, } from '@haaretz/fela-utils';
import { htzTheme, } from '@haaretz/htz-theme';
import styleRenderer from '../components/styleRenderer/styleRenderer';

const initialState = () => ({
  a11yToggle: false,
  isAnimationReduced: false,
  articleId: null,
  articleType: null,
  authorsContentId: null,
  articleSection: {
    name: null,
    id: null,
    url: null,
    __typename: 'ArticleSection',
  },
  canonicalUrl: '',
  path: null,
  isBot: false,
  commentsElementId: null,
  isMouseStory: false,
  //  makes sure that if we have another outbrain element on the page it calls outbrains
  // reload function script before calling the outbrain json api
  isOsakaDisplayed: false,
  isOsakaDisabled: false,
  isMastheadSticky: false,
  isStickyMastheadDisabled: false,
  pageGallery: {
    isOpen: false,
    startWith: null,
    __typename: 'PageGallery',
  },
  osakaCanRender: false,
  pageType: null,
  platform: null,
  isBlocked: null,
  isSuperContent: false,
  readingListArray: [],
  userNickName: null,
  zenMode: false,
  isPremiumContent: null,
  isCloseArticle: null,
  pageDateTimeString: null,
  scriptsToRender: null,
  sectionId: null,
  twitterTitle: null,
  fbScriptLoaded: false,
  isLabel: false,
  accessibilityColorMode: 'default',
  isReadyToRenderExternals: true,
  htzUser: userScheme,
});

type Props = {
  children: ChildrenArray<Node> | Node,
  url: {
    query: {
      path: string,
      isGroupS: boolean,
    },
  },
  pathname: string,
  client: {
    mutate: any => void,
  },
};

function AppWrapper({ children, url, pathname, client, ...props }: Props) {
  return (
    <>
      <StyleProvider renderer={styleRenderer} theme={htzTheme}>
        {children}
      </StyleProvider>
    </>
  );
}

function AppWrapperWithApollo(props) {
  return <ApolloConsumer>{client => <AppWrapper client={client} {...props} />}</ApolloConsumer>;
}

export default withApollo({ ssr: true, })(createApp(null, AppWrapperWithApollo), initialState);
