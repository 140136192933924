// @flow

// Values passed in query object from node server are sometimes
// coerced to strings client-side.
// Therefore when checking if a value, that we expect to be a boolean, is true,
// we should use this util to avoid false positives (i.e { <someValue>: 'false' }).
// This is especially important when using the `useRouter` hook.
export default function isQueryValueTrue(value: any): boolean {
  return value === true || value === 'true';
}
