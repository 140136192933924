const layoutStyle = Object.freeze({
  contPaddingS: 3,
  startColumnWidthL: 22,
  startColumnWidthXL: 30,
  startColumnPadding: 2,
  startColumnPaddingXL: 3,
  sectionTitleATypeScaleFromL: 3,
  sectionTitleALines: 2,


});

export default layoutStyle;
